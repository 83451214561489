import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import Collapsible from 'react-collapsible'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { useMediaQuery } from 'react-responsive'
import { PlayButton } from '../PlayButton'
import FavoriteButton from '../FavoriteButton'
import SomeButtons from '../SomeButtons/SomeButtons'
import { breakpoints, colors, fonts } from '../../styles/variables'
// @ts-ignore
import Video from '../../resources/icons/video-icon.svg'
import VideoPlayer from '../VideoPlayer'

import './Collabsible.css'
import { useMst } from '../../models/Root'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding: 0 10px;
  }
`

const CollapsibleContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 30px;
  align-items: baseline;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column;
    padding: 20px;
  }
`

const DateContainer = styled.div`
  min-width: 90px;
  margin: 0 30px 0 15px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    margin-left: 0;
  }
`

const LeftContainer = styled(DateContainer)`
  @media ${`(max-width: ${breakpoints.sm}px)`} {
    display: none;
  }

  @media ${`(max-width: ${breakpoints.lg}px)`} {
    margin: 0 15px;
    min-width: 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column;
  }
`

const DuraPublishSpan = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: ${colors.ui.hrcolor};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    text-align: left;
  }
`

const Image = styled.img`
  height: 300px;
  align-self: flex-start;
  margin-bottom: 35px;
`

const TitleText = styled.div`
  font-family: ${fonts.montserrat};
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding-top: 6px;
  }
`

const ExpandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 150px;
`

const ExpandText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: ${colors.ui.hrcolor};
`

const PlayButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0px 30px 5px 30px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    margin: 30px 0 0 15px;
  }
`

const PlayText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.black};

  @media ${`(min-width: ${breakpoints.md}px)`} {
    font-size: 14px;
    line-height: 17px;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px 43px 30px;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding: 0 20px 30px 20px;
  }

  @media ${`(min-width: ${breakpoints.md}px)`} {
  }

  @media ${`(min-width: ${breakpoints.xl}px)`} {
  }

  @media ${`(min-width: 1470px)`} {
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const EpisodeDescription = styled.p`
  font-family: ${fonts.codeProMono};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  white-space: pre-line;
  align-self: flex-start;
  word-break: break-word;
  padding-right: 20%;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding-right: 0;
  }
`

const FavoriteContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    align-items: flex-start;
    flex-direction: column;
  }
`

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ShareText = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  margin-bottom: 4px;
`

const VideoButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 10;
  background-color: ${colors.black};
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 100px;
  cursor: pointer;
  transition: 250ms all;
  border: none;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 38px;
    height: 38px;
  }
`

interface Props {
  title: string
  duration: number | undefined
  publishStart: Date
  description: string
  imageSrc: string | undefined
  id: number
  showName: string
  URI: string
  mediaSeriesId: number
  mediaType?: string
}

const styles = {
  videoIconSmall: {
    width: 23,
    height: 23,
  },
  videoIconLarge: {
    width: 40,
    height: 40,
  }
}

const MediaFileCollapsible: React.FC<Props> = observer((props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl })
  const [open, setOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const { mediaPlayer } = useMst()
  const { id, title, duration, publishStart, description, imageSrc, showName, mediaSeriesId, mediaType } = props
  const formTitleElement = (title: string, publishStart: Date, duration: number) => {
    return (
      <CollapsibleContainer>
        <TitleWrapper>
          <DateContainer>
            <DuraPublishSpan>{moment(publishStart).format('DD.MM.yyyy')}</DuraPublishSpan>
          </DateContainer>
          <TitleText>{title}</TitleText>
        </TitleWrapper>
        {open ? (
          <ExpandContainer>
            <ExpandText>Sulje</ExpandText>
            <ExpandLess fontSize="large" style={{ color: colors.black }} />
          </ExpandContainer>
        ) : (
            <ExpandContainer>
              <ExpandText>Jakson tiedot</ExpandText>
              <ExpandMore fontSize="large" style={{ color: colors.black }} />
            </ExpandContainer>
          )}
      </CollapsibleContainer>
    )
  }

  const titleElement = formTitleElement(title, publishStart, duration!)

  const toggleOpen = () => {
    setOpen(!open)
  }

  const playVideo = () => {
    mediaPlayer.setPause(true)
    setVideoOpen(!videoOpen)
  }

  const renderButton = () => {
    if (mediaType === 'video') {
      return (<PlayButtonContainer>
        <VideoButton onClick={playVideo}>
          <Video fill={colors.white} style={isMobile ? styles.videoIconSmall : styles.videoIconLarge} />
        </VideoButton>
        <VideoPlayer url={props.URI} videoOpen={videoOpen} setVideoOpen={(value) => setVideoOpen(value)} />
        <PlayText>katsele</PlayText>
      </PlayButtonContainer>)
    }
    return (<PlayButtonContainer>
      <PlayButton
        mediaId={id}
        showName={showName}
        mediaSrc={decodeURIComponent(props.URI).includes('http') ? props.URI : 'https://jako.dei.fi/ondemand/lowbit/' + props.URI}
        title={title}
        duration={duration}
        mediaSeriesId={props.mediaSeriesId}
        buttonColor={colors.white}
        backgroundColor={colors.red}
      />
      <PlayText>kuuntele</PlayText>
    </PlayButtonContainer>)
  }

  return (
    <Wrapper>
      <Collapsible trigger={titleElement} onOpening={toggleOpen} onClosing={toggleOpen}>
        <Container>
          <LeftContainer />
          <DescriptionContainer>
            {imageSrc && <Image src={imageSrc} />}
            <EpisodeDescription>{description}</EpisodeDescription>
            {mediaType !== 'video' && <FavoriteContainer>
              <ShareContainer>
                <ShareText>Jaa jakso someen</ShareText>
                <SomeButtons url={`https://deiplus.fi/ohjelmasarja/${mediaSeriesId}`} />
              </ShareContainer>
              <FavoriteButton series={false} id={id} marginTop={20} />
            </FavoriteContainer>}
          </DescriptionContainer>
        </Container>
      </Collapsible>
      {renderButton()}
    </Wrapper>
  )
})

export { MediaFileCollapsible }
