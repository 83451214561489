import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import { colors, fonts } from '../styles/variables'

interface Props {
  title: string,
  imageSrc?: string;
  description?: string;
}

const HeaderContainer = styled.div`
  background: ${colors.gray.ligh};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0;
  width: 100vw;
  padding: 10px;

  @media ${`(min-width: 851px)`} {
    width: 100%;
    max-width: 1300px;
    flex-direction: row;
    margin-top: 50px;
    padding: 20px 30px 20px 50px;
  }
`

const Image = styled.img`
  align-items: flex-start;
  width: 250px;
  height: 250px;

  @media ${`(max-width: 850px)`} {
    width: 185px;
    height: 185px;
    padding-bottom: 30px;
  }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
    padding: 5px 0 5px 30px;

    @media ${`(max-width: 850px)`} {
      width: 100%;
      text-align: center;
      heihgt: 185px;
      margin: 0px;
      padding-left: 0px;
      padding-bottom: 30px;
    }
  `

const Header = styled.div`
    font-family: ${fonts.montserrat};
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: ${colors.black};

    @media ${`(max-width: 850px)`} {
      font-size: 20px;
      line-height: 24px;
    }
`

const Description = styled.div`
  font-family: ${fonts.ptMono};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height:25px;
  color: ${colors.black};
  margin-top: 20px;

  @media ${`(max-width: 850px)`} {
    font-family: ${fonts.codeProMono};
    font-size: 14px;
    line-height: 16px;
  }
  `

const SeriesPageHeader: React.FC<Props> = ({ title, imageSrc, description }) => {
  const isMobile = useMediaQuery({ maxWidth: 850 });

  return (
    <HeaderContainer>
      { !isMobile && imageSrc && <Image src={imageSrc} />}
      <TextContainer>
        <Header>{title}</Header>
        <Description>{description}</Description>
      </TextContainer>
      { isMobile && imageSrc && <Image src={imageSrc} />}
    </HeaderContainer>
  )
}

export default SeriesPageHeader;
