import * as React from 'react'

import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'

import { useLocation, useParams } from '@reach/router'
import { getMediaSeriesById } from '../services/api'
import { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
// @ts-ignore
import ThumbsIcon from '../resources/icons/thumbs.svg'
import { MediaSeriesFilesCollapsible } from '../components/MediaSeriesFilesCollapsible'
import { useMediaQuery } from 'react-responsive'
import FavoriteButton from '../components/FavoriteButton'
import InfiniteScroll from 'react-infinite-scroll-component'
import SomeCard from '../components/SomeCards'
import SeriesPageHeader from '../components/SeriesPageHeader'
import { breakpoints, colors, fonts } from '../styles/variables'
import FooterBanner from '../components/DynamicBanner/FooterBanner'

const SeriesContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
  overflow-y: hidden;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
    width: 100wv;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100wv;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`
const FavoriteContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-self: flex-end;
  width: auto;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    justify-content: center;
    background: ${colors.black};
  }
`
const MediaSeriesContainer = styled.div`
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1150px;
  margin-top: 100px;
  justify-content: flex-start;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100%;
    max-width: 850px;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 1150px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100wv;
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
  }
`
const MoreButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-itmes: center;
  background: none;
  border: none;
  margin-left: 20px;
  margin-top: 20px;
`

const ButtonText = styled.div`
  margin: auto 0 auto 4px;
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;

  color: ${colors.deiRed};
`

const MediaSeriesPage = (props: any) => {
  const location = useLocation()
  const params = useParams();
  const [apiResult, setApiResult] = useState(null)
  const [apiFetched, setApiFetched] = useState(false)
  const [apiPage, setApiPage] = useState(1)
  const [apiHasMoreResults, setApiHasMoreResults] = useState(true)
  const isDesktop = useMediaQuery({ minWidth: 1280 })
  const urlParams = new URLSearchParams(location.search);
  const mediaType = urlParams.get('mediaType')

  if (location.pathname && !apiResult) {
    const mediaSeriesId = props['*']
    getMediaSeriesById(mediaSeriesId, mediaType!).then((data) => {
      if (mediaType !== 'video') {
        data.data.mediaFiles.sort((a, b) => new Date(b.premierDate) - new Date(a.premierDate))
      }
      setApiResult(data.data)
      setApiFetched(true)
    })

  } else {
    // Something went wrong, no params?
  }

  const fetchData = () => {
    const mediaSeriesId = props['*']


    getMediaSeriesById(mediaSeriesId, mediaType!, apiPage + 1, 25).then((data) => {
      if (!apiFetched) {
        return
      }

      if (mediaType === 'video') {
        if (data.data?.videos.length === 0) {
          setApiHasMoreResults(false)
          return
        }

        // @ts-ignore
        const newMediaFiles = [...apiResult?.videos, ...data.data?.videos]
        newMediaFiles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        const apiResultClone = apiResult
        // @ts-ignore
        apiResultClone.videos = newMediaFiles
        // @ts-ignore
        setApiResult(apiResultClone)
      } else {
        if (data.data?.mediaFiles.length === 0) {
          setApiHasMoreResults(false)
          return
        }

        // @ts-ignore
        const newMediaFiles = [...apiResult?.mediaFiles, ...data.data?.mediaFiles]
        newMediaFiles.sort((a, b) => new Date(b.premierDate) - new Date(a.premierDate))

        const apiResultClone = apiResult

        // @ts-ignore
        apiResultClone.mediaFiles = newMediaFiles

        // @ts-ignore
        setApiResult(apiResultClone)
      }
      setApiPage(apiPage + 1)
    })
  }

  const navigateTo = () => {
    if (apiResult.seriesSource === 'rss') {
      navigate('/podcastit')
    } else {
      navigate('/teemat')
    }
  }

  const renderVideoResults = () => (
    <InfiniteScroll
      // @ts-ignore
      dataLength={apiResult?.videos.length ?? 0} //This is important field to render the next data
      next={fetchData}
      hasMore={apiHasMoreResults}
      loader={<></>}
      style={{ width: '100%', overflow: undefined }}
    >
      <MediaSeriesFilesCollapsible files={apiResult?.videos} mediaType={mediaType!} />
    </InfiniteScroll>
  )

  const renderAudioResults = () => (
    <InfiniteScroll
      // @ts-ignore
      dataLength={apiResult?.mediaFiles.length ?? 0} //This is important field to render the next data
      next={fetchData}
      hasMore={apiHasMoreResults}
      loader={<></>}
      style={{ width: '100%', overflow: undefined }}
    >
      <MediaSeriesFilesCollapsible files={apiResult?.mediaFiles} mediaType={mediaType!} />
    </InfiniteScroll>
  )

  return (
    <Page>
      <SomeCard title={apiResult?.name} image={apiResult?.imageSrc} description={apiResult?.description} />
      <SeriesContainer>
        <ButtonContainer>
          <MoreButton onClick={navigateTo}>
            <ButtonText>Lisää samankaltaisia</ButtonText>
          </MoreButton>
        </ButtonContainer>
        <HeaderContainer>
          <SeriesPageHeader
            title={apiResult?.name ?? ''}
            imageSrc={apiResult?.imageSrc}
            description={apiResult?.description}
          />
          {mediaType !== 'video' && <FavoriteContainer>
            <FavoriteButton series={true} id={Number(props['*'])} />
          </FavoriteContainer>}
        </HeaderContainer>
        <MediaSeriesContainer>
          {mediaType === 'video' ? renderVideoResults() : renderAudioResults()}
        </MediaSeriesContainer>
        <FooterBanner />
      </SeriesContainer>
    </Page >
  )
}

export default MediaSeriesPage
