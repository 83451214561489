import React from 'react'
import { observer } from 'mobx-react-lite'

import { MediaFileCollapsible } from './MediaFileCollapsible'
// @ts-ignore
import PlayIcon from '../../resources/icons/playbutton.svg'
// @ts-ignore
import CollapsibleOpenIcon from '../../resources/icons/collapsible-open.svg'
// @ts-ignore
import CollapsibleClosedIcon from '../../resources/icons/collapsible-closed.svg'

interface Props {
  files: MediaFileAudio[] | MediaFileVideo[]
  mediaType?: string
}

interface MediaFileAudio {
  URI: string
  description: string
  duration: number
  id: number
  publishStart: Date
  title: string
  type: string
  xmlId: number
  showName: string
  imageSrc?: string
  mediaSeries: any[]
  premierDate: Date
}

interface MediaFileVideo {
  originalUrl: string
  description: string
  id: number
  createdAt: Date
  title: string
  thumbnailUrl?: string
  showName: string
  seriesId: number
}

const MediaSeriesFilesCollapsible: React.FC<Props> = observer((props) => {
  const renderVideo = () => {
    if (props.files) {
      return props.files.map((f) => (
        <MediaFileCollapsible
          key={`${f.id}-${f.title}`}
          id={f.id}
          URI={f.originalUrl}
          showName={f.showName}
          title={f.title}
          description={f.description}
          duration={undefined}
          publishStart={f.createdAt}
          imageSrc={f.thumbnailUrl}
          mediaSeriesId={f.seriesId}
          mediaType={props.mediaType}
        />
      ))
    }
    return null
  }

  const renderAudio = () => {
    if (props.files) {
      return props.files.map((f) => (
        <MediaFileCollapsible
          key={`${f.id}-${f.title}`}
          id={f.xmlId}
          URI={f.URI}
          showName={f.showName}
          title={f.title}
          description={f.description}
          duration={f.duration}
          publishStart={f.premierDate}
          imageSrc={f.imageSrc}
          mediaSeriesId={f.mediaSeries.id}
          mediaType={props.mediaType}
        />
      ))
    }
    return null
  }
  return props.mediaType === 'video' ? renderVideo() : renderAudio()
})

export { MediaSeriesFilesCollapsible }
