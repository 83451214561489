import React from "react"
import { Helmet } from "react-helmet"

interface SomeCardProps {
  description: string,
  image: string,
  title: string,
}

const SomeCard: React.FC<SomeCardProps> = ({ description, image, title }) => {

  const metaDescription = description;
  return (
    <Helmet

      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'og:description',
          content: metaDescription,

        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:title',
          content: title
        }
      ].concat(
        image
          ? [
            {
              property: "og:image",
              content: image,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
          ]
          : [
            {
              name: "twitter:card",
              content: "summary",
            },
          ]
      )}
    />
  )
}


SomeCard.defaultProps = {
  description: ``,
}

export default SomeCard;
